<template>
    <profile-layout>
        <template #header>
            Your information
        </template>
        <template #content>
            <avatar-component
                v-if="user"
                :avatar="user.avatar_url"
                :avatar-letter="avatarLetter"
                @finish="getUser"
            />
            <div class="profile__body__fields">
                <div
                    v-for="(field, index) in fields"
                    :key="index"
                    class="profile__body__fields__item"
                >
                    <div class="profile__body__fields__item_data">
                        <div class="profile__body__fields__item__name">
                            {{ field.name }}:
                        </div>
                        <div class="profile__body__fields__item__value">
                            <span v-if="field.value">{{ field.value }}</span>
                            <span v-else> {{ field.name }} is empty  </span>
                        </div>
                    </div>
                    <div
                        v-if="field.link"
                        class="profile__body__fields__item__link"
                        @click="redirectTo(field.link)"
                    >
                        <pencil />
                    </div>
                </div>
            </div>
        </template>
    </profile-layout>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'

import ProfileLayout from '@/layouts/ProfileLayout'
import AvatarComponent from '@/components/profile/AvatarComponent'

import Pencil from '@/icons/Pencil';

import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth');

export default {
    name: 'UserProfile',
    components: { ProfileLayout, Pencil, AvatarComponent },
    computed: {
        ...mapState('auth', [
            'user'
        ]),
        avatarLetter() {
            if (this.user && this.user.firstname && this.user.lastname) {
                return this.user.firstname[0] + this.user.lastname[0]
            }
            return ''
        },
        fields() {
            return [
                {
                    name: 'Account name',
                    value: `${this.user.firstname} ${this.user.lastname}`,
                    link: 'change-user-name'
                },
                {
                    name: 'User nickname',
                    value: this.user.nickname,
                    link: 'change-user-nick-name'
                },
                {
                    name: 'Email',
                    value: this.user.email,
                    link: 'change-email'
                },
                {
                    name: 'Password',
                    value: '**********',
                    link: 'change-password'
                },
                {
                    name: 'Phone',
                    value: `(${this.user.phone_code}) ${this.user.phone}`,
                    link: 'change-phone'
                },
                {
                    name: 'Biography',
                    value: this.user.biography,
                    link: 'change-biography'
                }
            ]
        }
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        redirectTo(name) {
            this.$router.push({ name })
        },
        getUser() {
            this[ACTION_GET_CURRENT_USER]()
        }
    }
}
</script>

<style scoped lang="scss">
.profile__body {
   &__fields {
       margin-top: 50px;
       &__item{
           display: flex;
           justify-content: space-between;
           margin: 30px auto;
            &_data{
                width: 100%;
                display: flex;
                align-items: center;
            }
            &__name, &__value {
                font-size: 14px;
                display: flex;
                min-width: 140px;
                align-items: center;
                justify-content: flex-start;
            }
            &__value {
                min-width: calc(100% - 140px - 32px);
                font-weight: 500;
                color: #1f2939;
                text-align: left;
                word-break: break-all;
                span {
                    white-space: break-spaces;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            &__name {
                font-weight: 400;
                color: #818181;
            }
            &__link {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all .2s ease;
                &:hover {
                    background: rgba(79, 79, 79, 0.1);
                    color: $orange;
                }
            }
       }
   }
}
</style>
