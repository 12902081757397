<template>
    <div class="avatar">
        <label
            class="avatar__label"
            :class="{ 'drag' : dragMode }"
            for="files_upload2"
            @dragenter.prevent
            @drop.prevent="drop"
            @dragover.prevent="dragover"
            @dragleave.prevent="dragleave"
        >
            <account-icon
                v-if="!cropedImage || loadError"
                class="avatar__label__icon"
                :width="35"
                :height="35"
            />
            <img
                v-else
                :src="cropedImage"
                alt="avatar"
                @error="loadError = true"
            >
            <span class="avatar__label__text"> Upload profile picture </span>
        </label>
        <input
            id="files_upload2"
            ref="input"
            name="uploaded_image"
            type="file"
            accept="image/*"
            class="avatar__uploader"
            multiple
            @change="setImage($event)"
        >
        <croper-modal
            title="Crop your photo"
            name="avatar"
            :circle-mode="true"
            @rotate="rotate"
        >
            <template #content>
                <vue-cropper
                    v-if="image"
                    ref="cropper"
                    :src="image"
                    alt="Source Image"
                    :aspect-ratio="1"
                    preview=".preview"
                    class="avatar-cropper"
                />
            </template>
        </croper-modal>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import Api from '@/helpers/api/index.js'
import AccountCircle from 'mdi-vue/AccountCircle.vue'
import CroperModal from '@/components/modals/CroperModal'
import modalsMixins from '@/mixins/modals/modalsMixins'
import VueCropper from 'vue-cropperjs'
import Compressor from 'compressorjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'

export default {
    name: 'AvatarComponent',
    components: {
        CroperModal,
        VueCropper,
        AccountIcon: AccountCircle
    },
    mixins: [modalsMixins],
    props: {
        avatar: {
            type: String,
            default: ''
        },
        avatarLetter: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            image: null,
            cropedImage: '',
            dragMode: false,
            fileName: '',
            loadError: false
        }
    },
    created() {
        this.loadError = false
        this.cropedImage = this.avatar
        eventBus.$on('hideModal', this.hideModal)
        eventBus.$on('confirm', this.sendImage)
    },
    methods: {
        sendImage(name) {
            this.loadError = false
            if (name !== 'avatar') { return }
            const $vm = this
            this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
                new Compressor(blob, {
                    quality: 1,
                    async success(result) {
                        if (result.size / 1024 / 1024 > 8) {
                            eventBus.$emit('showSnackBar', 'File size exceeds 8MiB', 'error');
                            throw new Error('Error')
                        }
                        const formData = new FormData();
                        formData.append('file', result, $vm.fileName);
                        await $vm.loadAvatar(formData)
                        $vm.hideModal(name)
                    },
                    error(err) {
                        console.log(err.message);
                    }
                });
            });
        },
        loadAvatar(formData) {
            return Api.post('/api/file-storage/users/upload-avatar',
                formData,
                {
                    'Content-Type': 'multipart/form-data',
                    onUploadProgress: (progressEvent) => {
                        this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                })
                .then((response) => {
                    this.cropedImage = response.data.url
                    this.$emit('finish')
                })
                .catch((error) => {
                    eventBus.$emit('showSnackBar', error.message, 'error');
                })
        },
        hideModal(name) {
            if (name !== 'avatar') { return }
            this.image = null
            this.$refs.input.value = ''
        },
        setImage(e) {
            this.loadImage(e.target.files[0])
        },
        drop(e) {
            this.$refs.input.files = e.dataTransfer.files
            this.dragMode = false
            this.loadImage(e.dataTransfer.files[0])
        },
        dragover() {
            if (!this.dragMode) {
                this.dragMode = true
            }
        },
        dragleave() {
            this.dragMode = false
        },
        loadImage(file) {
            this.fileName = file.name
            const reader = new FileReader()
            reader.onload = (event) => {
                this.image = event.target.result
                this.openModal('avatar')
            };
            reader.readAsDataURL(file)
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        }
    }
}
</script>

<style scoped lang="scss">
.avatar {
    border: 2px dashed #D3D9E2;
    &__label {
        cursor: pointer;
        height: 105px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
        transition: background-color .5s ease;
        &.drag{
            background-color: #ccc;
        }
        &__text {
            color: #1F2939;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
        &__icon {
            color: #D3D9E2;
            width: 35px;
            height: 35px;
            margin-bottom: 10px;
        }
        img {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
            border-radius: 50%;
        }
    }
    &__uploader {
        display: none;
    }
}

</style>
